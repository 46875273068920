.keyzy-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.keyzy-tooltip-content,
.rc-slider-tooltip-inner {
  font-size: 32px;
  font-weight: 700;
  font-family: Outfit;

  backround: none;
  background-color: none !important;
}

.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 0.5s linear infinite alternate;
}
.skeleton-text {
  width: 100%;
  height: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 0.125rem;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
