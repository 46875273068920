body {
  margin: 0;
}

.main {
  width: 595px;
  height: 842px;
  padding: 32px;
  position: relative;
  background: #f9faef;
  font-family: "Outfit";
  font-style: revert;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin: 0;
}

.main.dark {
  background: #1b1e28;
  color: #fff;
}

.bold {
  font-weight: 700;
}

.secondColor {
  color: #f39237;
}

.header {
  width: 100%;
  height: 100px;
}

.logo {
  float: left;
  width: 111px;
  height: 27.57px;
  left: 32px;
  top: 32px;
}

.costumers {
  right: 34px;
  top: 34px;
  float: right;
}

.costumers .item {
  text-align: right;
  padding-bottom: 24px;
}

.costumers .item .column {
  float: right;
  margin-bottom: 14px;
}

.titles {
  width: 369px;
  height: 80px;

  /* Outfit — 32pt — bold — left */

  font-family: "Outfit";
  font-style: revert;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 24px;
  /* margin-top: 15px; */

  /* grey/800 */

  color: #293042;

  /* Inside auto layout */

  /* flex: none;
  order: 0;
  flex-grow: 0; */
}

.titles.dark {
  color: #fff;
}

.addmarginBottom {
  margin-bottom: 8px;
}

.addmarginLeft {
  margin-left: 24px;
}

.budget {
  display: block;
  padding: 0 24px;

  width: 531px;
  height: 74px;

  background: rgba(243, 146, 55, 0.2);
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.budget .text {
  width: 282px;
  height: 46px;
  float: left;
  margin-top: 14px;
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;

  /* grey/700 */

  color: #3e465c;
}

.budget .value {
  width: 180px;
  height: 50px;
  float: right;
  margin-top: 11px;

  /* Outfit — 40pt — bold — left */

  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;

  /* grey/800 */

  color: #293042;
}

.budget-description {
  padding: 0 24px;
  width: 531px;
  height: 74px;

  background: rgba(39, 47, 66, 0.11);
  border-radius: 8px;
}

.budget-description .text {
  width: 218px;
  height: 30px;
  float: left;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;

  /* grey/700 */

  color: #3e465c;
}

.budget-description .value {
  height: 45px;
  float: right;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-top: 14px;
  padding-top: 8px;

  /* grey/800 */

  color: rgba(41, 48, 66, 0.75);
}

.budget-info {
  padding: 15px 24px 14px;
  width: 531px;
  background: rgba(18, 121, 178, 0.12);
  border-radius: 8px;
}

.budget-info .title {
  width: 483px;
  height: 15px;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 12px;

  color: rgba(41, 48, 66, 0.75);
}

.budget-info .text {
  height: 45px;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: rgba(41, 48, 66, 0.75);

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.process {
  padding: 24px 24px 0 24px;

  width: 531px;
  height: 238px;

  /* grey/800 */

  background: #293042;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.process .items {
  width: 100%;
}

.process .title {
  width: 100%;
  height: 25px;
  margin-bottom: 20px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  /* White */

  color: #ffffff;
}

.process .item {
  float: left;
  align-items: center;
  text-align: left;
  width: 33.33%;
  margin-bottom: 32px;
}

.process .item.orange .number {
  background: #f39237;
  color: #1b1e28;
}

.process .item.white .number {
  background: #ffffff;
  color: #1b1e28;
  width: 25px;
  height: 25px;
  line-height: 24px;
}

.process .item .number {
  width: 20px;
  height: 20px;
  float: left;
  background: #3584c8;
  border-radius: 20px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  color: #ffffff;
  justify-content: space-around;
}

.process .item .number.addLine::after {
  content: "";
  width: 1px;
  height: 16px;
  position: absolute;
  background: #677599;
  left: 10px;
  top: 33px;
}

.process .item .text {
  font-family: "Marcellus";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  float: left;
  margin-left: 8px;

  /* White */

  color: #ffffff;
}

.quote-offer .header {
  height: 80px;
}

.quote-offer .content .title {
  font-size: 32px;
  line-height: 40px;
  font-weight: bold;
  margin-bottom: 12px;
  margin-top: 0;
}

.quote-offer .content .price-property {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.quote-offer .content .price-property .purchase-price,
.quote-offer .content .price-property .property {
  background-color: #293042;
  border-radius: 8px;
  padding: 8px 14px;
  height: "auto";
}

.quote-offer .content .price-property .purchase-price {
  width: 50%;
  margin-right: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: "100%";
}

.quote-offer .content .price-property .purchase-price .title,
.quote-offer .content .price-property .property .title {
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  color: #fff;
}

.quote-offer .content .price-property .purchase-price .price {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 18px;
}

.quote-offer .content .price-property .property {
  width: 50%;
  margin-left: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.quote-offer .content .price-property .property .property-image {
  margin-top: 14px;
  margin-right: 26px;
}

.quote-offer .lease-period,
.quote-offer .monthly-payments,
.quote-offer .one-off-fees,
.quote-offer .buying-your-home {
  padding: 10px 18px;
  width: "100%";
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #3584c8;
  display: flex;
  flex-direction: column;
}

.quote-offer .lease-period .title,
.quote-offer .monthly-payments .title,
.quote-offer .one-off-fees .title,
.quote-offer .buying-your-home .title {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 4px;
}

.quote-offer .lease-period .content-block .content-item,
.quote-offer .monthly-payments .content-block .content-item,
.quote-offer .one-off-fees .content-block .content-item,
.quote-offer .buying-your-home .content-block .content-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
}

.quote-offer .lease-period .content-block,
.quote-offer .monthly-payments .content-block,
.quote-offer .one-off-fees .content-block,
.quote-offer .buying-your-home .content-block {
  display: flex;
  flex-direction: column;
}

.quote-offer .steps-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  position: relative;
}

.quote-offer .steps-block .step-outer {
  display: flex;
  background: #1b1e28;
  border-radius: 20px;
  border: 1px solid #1b1e28;
  padding: 1px;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 24px;
  height: 24px;
}

.quote-offer .steps-block .step-outer.active {
  border: 1px solid #fff;
}

.quote-offer .steps-block .step-outer .active-title {
  position: absolute;
  top: 38px;
  font-size: 7px;
  line-height: 9px;
}

.quote-offer .steps-block .step-outer.active::after {
  content: "";
  position: absolute;
  top: 30px;
  height: 6px;
  width: 1px;
  background: #677599;
}

.quote-offer .steps-block .step-outer .step-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  background: #3584c8;
  border-radius: 20px;
  font-family: "Outfit";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}

.quote-offer .steps-block .step-outer .step-inner.active {
  background: #fff;
  color: #1b1e28;
}

.quote-offer .steps-block .step-outer .step-inner.orange {
  background: #f39237;
  color: #1b1e28;
}
.quote-offer .steps-block .step-outer .step-inner.last::after {
  content: none;
}

.quote-offer .steps-block .step-outer .step-inner::after {
  content: "";
  position: absolute;
  left: 27px;
  height: 1px;
  width: 37px;
  background: #677599;
  border-radius: 1rem;
  border-color: #fff;
}

.footer {
  width: 531px;
  height: 39px;

  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  margin-top: 24px;
  color: #727272;
}

.quote-offer .footer {
  color: #fff;
  margin-top: 32px;
}
