html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  overflow-x: hidden !important;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scroll-behavior: smooth;
}

body > div#__next {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

.sourceType {
  padding-bottom: 0;
}

.sourceType div {
  width: 100%;
  margin-right: 0;
}

.sourceType div:nth-child(n + 4) {
  flex: 1;
  margin-right: 1rem;
}

.sourceType div:nth-child(6) {
  margin-right: 0 !important;
}

.markdownContent p {
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 36px;
}

.markdownContent h1,
.markdownContentListings h1 {
  font-size: 2.25rem;
  font-weight: 600;
  margin-block: 8px;
}

.markdownContent h2,
.markdownContentListings h2 {
  font-size: 1.875rem;
  font-weight: 500;
  margin-block: 8px;
}

.markdownContent h3,
.markdownContentListings h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-block: 8px;
}

.markdownContent h4,
.markdownContentListings h4 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-block: 8px;
}

.markdownContent ul,
.markdownContentListings ul {
  list-style-type: disc;
  margin-block: 24px;
  margin-inline: 24px;
  font-size: 24px;
}
.markdownContentListings ul {
  margin-block: 18px;
  padding-inline-start: 18px;
  font-size: 24px;
}

.markdownContent ol,
.markdownContentListings ol {
  list-style-type: decimal;
  margin-block: 16px;
  margin-inline: 24px;
  list-style-position: outside;
  font-size: 24px;
}

.markdownContent blockquote,
.markdownContentListings blockquote {
  margin-top: 40px;
  margin-bottom: 32px;
  font-size: 0.875rem;
  font-weight: 400;
  border-left: 4px solid rgb(234, 234, 239);
  font-style: italic;
  padding: 8px 20px;
}

.markdownContent pre,
.markdownContent code,
.markdownContentListings pre,
.markdownContentListings code {
  font-size: 0.875rem;
  border-radius: 4px;
  background-color: rgb(50, 50, 77);
  max-width: 100%;
  overflow: auto;
  padding: 8px;
}

.markdownContent pre > code,
.markdownContentListings pre > code {
  color: rgb(131, 148, 150);
}

.markdownContent a,
.markdownContentListings a {
  color: rgb(243, 146, 55);
  cursor: pointer;
  text-decoration: underline;
}

.markdownContent u,
.markdownContentListings u {
  text-decoration: underline;
}

.customerReviewSlider .slick-slide {
  padding: 0 10px;
}

@media (max-width: 500px) {
  .customerReviewSlider .slick-slide {
    padding: 0 7px;
  }
}

@media (max-width: 475px) {
  .customerReviewSlider .slick-slide {
    padding: 0 5px;
  }
}
